import React from "react";
import "../dashboard/styles/Mentors.css";
import { SiLinkedin } from "react-icons/si";
import { SiInstagram } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { ImFacebook2 } from "react-icons/im";
import { Link } from "react-router-dom";

const Mentors = () => {
  const mentorData = [
    {
      name: "Abhishek Paswan",
      socialLinks: { linkedIn: "", email: "", facebook: "", ionstagram: "" },
      image: "/images/abhishek.jpg",
    },
    {
      name: "Lovely Singh",
      socialLinks: { linkedIn: "", email: "", facebook: "", ionstagram: "" },
      image: "/images/lovely.jpg",
    },
    {
      name: "Dr. Mithilesh Singh",
      socialLinks: { linkedIn: "", email: "", facebook: "", ionstagram: "" },
      image: "/images/Mithilesh-Singh.jpg",
    },
    {
      name: "Surbhi Singh",
      socialLinks: { linkedIn: "", email: "", facebook: "", ionstagram: "" },
      image: "/images/Surbhi-Singh.jpg",
    },
    {
      name: "Abhishek Paswan",
      socialLinks: { linkedIn: "", email: "", facebook: "", ionstagram: "" },
      image: "/images/abhishek.jpg",
    },
    {
      name: "Lovely Singh",
      socialLinks: { linkedIn: "", email: "", facebook: "", ionstagram: "" },
      image: "/images/lovely.jpg",
    },
    {
      name: "Dr. Mithilesh Singh",
      socialLinks: { linkedIn: "", email: "", facebook: "", ionstagram: "" },
      image: "/images/Mithilesh-Singh.jpg",
    },
    {
      name: "Surbhi Singh",
      socialLinks: { linkedIn: "", email: "", facebook: "", ionstagram: "" },
      image: "/images/Surbhi-Singh.jpg",
    },
  ];
  return (
    <div className="mentors-container">
      <h2>Our Mentors</h2>
      <div className="mentors-gallery">
        <div className="mentor-slider">
          {mentorData.map((mentor, index) => (
            <div key={index} className="mentor-slide">
              <img src={mentor.image} alt="Mentor 4" />
              <div className="mentor-details">
                <h4>{mentor.name}</h4>
                <span>Designation</span>
                <div className="mentor-email">
                  <span>Email:</span>
                  <a href="mailto:shivmouryacse@gmail.com">
                    shivmouryacse@gmail.com
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mentors;
