import React, { useMemo } from "react";
import Slider from "../components/dashboard/Sliders";
import ContactForm from "../components/dashboard/ContactForm";
import Mentors from "../components/dashboard/Mentor";
import AboutStartup from "../components/dashboard/AboutStartup";
import Initiative from "../components/dashboard/Initiative";

export default function Dashboard({ data }) {
  const handleScrolling = (targetSection) => {
    const element = document.querySelector(`.${targetSection}`); // Use the class or ID of the section
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const posters = useMemo(
    () => [
      "/images/startup-poster.jpg",
      "/images/backgrounds/startup-mahakumbh-to-tell-the-world-that-spring-is-coming.webp",
      "/images/backgrounds/Startup_Mahakumbh_PM_Narendra_Modi_ecosystem_1710915148775_1710915148910.webp",
      "/images/backgrounds/startup-mahakumbh-to-tell-the-world-that-spring-is-coming.webp",
      "/images/backgrounds/hashstudioz-at-startup-mahakumbh-expo.webp",
    ],
    []
  );

  const notice = useMemo(() => [
    {
      notice: "Upcoming Outreach program coming soon",
      url: "https://link1.com",
    },
    { notice: "Idea submission is live now", url: "https://link2.com" },
  ]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Slider
        posters={posters}
        notices={notice}
        onclickExplore={() => handleScrolling("initiative-container")}
      />
      <Initiative className={"initiative-container"} />
      <AboutStartup />
      <Mentors />
      <ContactForm />
    </div>
  );
}
